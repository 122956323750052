import { useState } from "react";
import { useTeamAccountUser } from "~/providers/TeamAccountUserProvider";
import { api } from "~/server/trpc/react";
import Button from "~/components/base/Button";
import Input from "~/components/base/Input";
import HeroPage from "~/components/layout/HeroPage";
import Notification from "~/components/base/Notification";
import { useRouter } from "next/navigation";
export default function HasNoTeamAccount() {
  const [teamName, setTeamName] = useState("");
  const { teamAccountUser } = useTeamAccountUser();
  const [noTeamName, setNoTeamName] = useState(false);
  const router = useRouter();
  const { mutate } = api.teamAccount.create.useMutation({
    onSuccess: () => {
      router.push("/");
    },
  });
  const createTeamAccount = () => {
    if (teamAccountUser == null) return;
    if (!teamName) {
      setNoTeamName(true);
      return;
    }
    mutate({ name: teamName });
  };
  if (teamAccountUser?.role !== "admin") {
    return <></>;
  }

  return (
    <HeroPage>
      <div className="flex h-screen w-1/2  flex-col content-center items-center justify-center">
        <div className="text mb-10 text-3xl font-semibold">
          Create an empty team
          {noTeamName && (
            <Notification id="CreateTeam" className="mt-4">
              Please enter a team name.
            </Notification>
          )}
        </div>

        <Input
          value={teamName}
          setValue={setTeamName}
          className="mb-4 !w-2/3 px-6 py-4 text-2xl"
          placeholder="My team name"
        />
        <Button
          className="flex w-2/3 justify-center px-6 py-4 text-2xl font-bold"
          onClick={createTeamAccount}
        >
          Create Team
        </Button>
      </div>
    </HeroPage>
  );
}
