"use client";
import { type TeamAccount } from "@openqlabs/drm-db";
import { useSession } from "next-auth/react";
import React, { createContext, useContext, useEffect, useState } from "react";
import HasNoTeamAccount from "~/components/LandingPages/HasNoTeamAccount";
import useInvite from "~/components/LandingPages/useInvite";
import { useTeamAccountUser } from "~/providers/TeamAccountUserProvider";
import { useLogger } from "./LoggerProvider";
import FreeTrialExpired from "~/components/LandingPages/FreeTrialExpired";
import CreateTeamAccount from "~/app/admin/create-team";
import { api } from "~/server/trpc/react";

interface TeamAccountContextType {
  teamAccounts: TeamAccount[];
  activeTeamAccount: TeamAccount;
  setTeamAccounts: () => void | null;
  setActiveTeamAccount: (teamAccount: TeamAccount) => void | null;
}

const TeamAccountContext = createContext<TeamAccountContextType | null>(null);

export function useTeamAccount() {
  return useContext(TeamAccountContext) as TeamAccountContextType;
}

export function TeamAccountProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [activeTeamAccount, setActiveTeamAccount] =
    useState<TeamAccount | null>(null);
  const handleSetActiveTeamAccount = (teamAccount: TeamAccount) => {
    localStorage.setItem("activeTeamAccountId", teamAccount.id);
    setActiveTeamAccount(teamAccount);
  };
  const logger = useLogger();
  const { teamAccountUser } = useTeamAccountUser();
  const [showCreateTeamAccount, setShowCreateTeamAccount] =
    useState<boolean>(false);

  const {
    data: teamAccounts,
    isLoading: loadingTeamAccounts,
    refetch,
    status,
  } = api.teamAccount.getActiveTeamAccountUserTeamAccounts.useQuery(
    {
      teamAccountUserId: teamAccountUser?.id || "",
    },
    { refetchOnWindowFocus: true }
  );

  useEffect(() => {
    const firstTeamAccount = teamAccounts?.[0];
    if (status === "success") {
      if (firstTeamAccount) {
        if (!activeTeamAccount) {
          const localStorageId = localStorage.getItem("activeTeamAccountId");
          const localStorageTeamAccount = teamAccounts.find(
            (teamAccount) => teamAccount.id === localStorageId
          );
          if (localStorageTeamAccount) {
            handleSetActiveTeamAccount(localStorageTeamAccount);
          } else {
            handleSetActiveTeamAccount(firstTeamAccount);
          }
        }
        setShowCreateTeamAccount(false);
      } else {
        setShowCreateTeamAccount(true);
      }
    }
  }, [teamAccounts]);

  const setTeamAccounts = () => {
    refetch().catch((err) => logger.error(err, "TeamAccountProvider.tsx1"));
  };
  const { data: session } = useSession();
  useEffect(() => {
    if (typeof refetch === "function") {
      refetch().catch((err) => logger.error(err, "TeamAccountProvider.tsx2"));
    }
  }, [refetch, session, logger]);

  useInvite(setTeamAccounts);

  if (showCreateTeamAccount && teamAccountUser?.role !== "admin") {
    return <HasNoTeamAccount />;
  }
  if (
    (activeTeamAccount?.freeTrialExpiryDate ?? 0) < Date.now() &&
    activeTeamAccount?.isFreeTrial &&
    teamAccountUser?.role !== "admin"
  ) {
    return <FreeTrialExpired />;
  }
  if (!activeTeamAccount || teamAccounts === undefined) {
    if (
      teamAccountUser?.role === "admin" &&
      !loadingTeamAccounts &&
      !teamAccounts?.length
    ) {
      return <CreateTeamAccount />;
    } else {
      return <></>;
    }
  }
  const teamAccountsContextValue = {
    teamAccounts,
    setTeamAccounts,
    activeTeamAccount: activeTeamAccount as TeamAccount,
    setActiveTeamAccount: handleSetActiveTeamAccount,
  };
  return (
    <TeamAccountContext.Provider value={teamAccountsContextValue}>
      {children}
    </TeamAccountContext.Provider>
  );
}
