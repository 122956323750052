import { useParams, usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import { useTeamAccountUser } from "~/providers/TeamAccountUserProvider";
import { api } from "~/server/trpc/react";

export default function useInvite(setTeamAccounts: () => void) {
  const { teamAccountUser } = useTeamAccountUser();
  const [tryOnce, setTryOnce] = useState<boolean>(true);
  const pathname = usePathname();
  const params = useParams();
  const { code } = params;
  const { mutate: acceptInvite } = api.teamAccount.joinTeam.useMutation({
    onSuccess: () => {
      setTeamAccounts();
      setTryOnce(false);
    },
  });
  useEffect(() => {
    if (
      code &&
      tryOnce &&
      !pathname.includes("verify-email") &&
      teamAccountUser
    ) {
      acceptInvite({ code: code as string });
    }
  }, [code, acceptInvite, pathname, teamAccountUser, setTeamAccounts, tryOnce]);
}
